<template>
  <div>
    <template
      v-if="
        typeof selected.inputs !== 'undefined' && selected.inputs.length > 0
      "
    >
      <v-list dense>
        <draggable
          :list="selected.inputs"
          @change="changeInputsOrder(selected, selected.inputs)"
          class="draggable"
          handle=".handle"
          draggable=".item"
        >
          <v-list-item
            v-for="(element, index) in selected.inputs"
            :key="index"
            class="item"
          >
            <v-list-item-icon>
              <v-icon class="handle">mdi-drag-horizontal</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title v-text="element.name"></v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </draggable>
      </v-list>
    </template>

    <template v-else>
      <v-alert type="info" :value="true" color="primary">
        No inputs available.
      </v-alert>
    </template>
    <confirm ref="confirmManageConnection"></confirm>
  </div>
</template>

<script>
import { mapGetters } from "vuex"
import EnginesMixins from "@/mixins/engines.js"
import Draggable from "vuedraggable"

export default {
  name: "treeview-inputs-list",
  props: {
    selected: {
      type: Object,
    },
  },
  components: {
    Draggable,
  },
  mixins: [EnginesMixins],
  methods: {},
  computed: {
    ...mapGetters({
      presetTreeviewEngines: "getPresetTreeviewEngines",
    }),
  },
}
</script>

<style>
.unattended-engine-card
  .v-expansion-panel--popout
  .v-expansion-panel__container,
.v-expansion-panel--inset .v-expansion-panel__container {
  max-width: 100%;
}

.draggable {
  /*cursor: all-scroll;*/
  width: 100% !important;
}
</style>
