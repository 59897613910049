var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-row',[_c('v-col',{attrs:{"cols":"12","xs":"12","sm":"4","md":"3"}},[_c('v-card',[_c('v-text-field',{attrs:{"outlined":"","label":"Search Engine","hide-details":"","clearable":"","clear-icon":"mdi-close-circle-outline"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-card-text',{class:_vm.$vuetify.breakpoint.smAndUp ? '' : 'treeview-wrapper-collapse'},[(_vm.checkTreeExists())?[_c('v-treeview',{attrs:{"items":_vm.presetTreeviewEngines,"open":_vm.open,"active":_vm.active,"item-key":"id","hoverable":"","activatable":"","search":_vm.search,"id":"tree-engines"},on:{"update:open":function($event){_vm.open=$event},"update:active":function($event){_vm.active=$event}},scopedSlots:_vm._u([{key:"prepend",fn:function({ item, open }){return [(item.treetype == 'preset')?_c('v-icon',[_vm._v(" "+_vm._s(open ? "mdi-folder-open" : "mdi-folder")+" ")]):_vm._e(),(item.treetype == 'engine')?[(item.isUnattendedOnline)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-icon',{class:[
                        item.isUnattendedOnline
                          ? 'green--text text--lighten-3'
                          : 'red--text text--lighten-3',
                      ]},[_vm._v(" mdi-remote ")])]}}],null,true)},[_c('span',[_vm._v("Unattended")])]):(true === false)?_c('v-icon',{class:[
                    _vm.checkIsStudio(item)
                      ? 'green--text text--lighten-3'
                      : 'red--text text--lighten-3',
                  ]},[_vm._v(" mdi-library-video ")]):_c('v-icon',{staticClass:"pl-1",class:[
                    item.is_online
                      ? 'green--text text--lighten-3'
                      : 'red--text text--lighten-3',
                  ]},[_vm._v(" mdi-monitor ")])]:_vm._e()]}},{key:"label",fn:function({ item, open }){return [(item.treetype == 'preset')?[_c('div',[_vm._v(_vm._s(item.name))])]:[(item.description)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('div',_vm._g({},on),[_vm._v(_vm._s(item.name))])]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.description ? item.description : "No description"))])]):_c('div',[_vm._v(_vm._s(item.name))])]]}}],null,false,1587814878)})]:_vm._e()],2)],1)],1),_c('v-col',{attrs:{"cols":"12","xs":"12","sm":"8","md":"9"}},[(_vm.selectedItem)?_c('treeview-item-preview',{attrs:{"selected":_vm.selectedItem}}):_vm._e(),(_vm.activePreset)?_c('treeview-preset-preview',{attrs:{"activePreset":_vm.activePreset}}):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }