<template>
  <v-dialog
    v-model="dialog"
    persistent
    max-width="600px"
    v-if="typeof selected !== 'undefined'"
    dark
    content-class="dialog-tree-connections"
  >
    <v-form data-vv-scope="form-tree-connections">
      <v-card>
        <v-card-title>
          <span class="headline">Connect engine {{ selected.name }} to</span>
        </v-card-title>
        <v-card-text>
          <v-container grid-list-md>
            <v-layout wrap>
              <v-flex xs12 sm12>
                <v-overflow-btn
                  v-model="targetEngine"
                  :items="getEnginesForConnection()"
                  item-text="name"
                  item-value="_key"
                  editable
                  label="Select connect engine"
                  hide-details
                  class="pa-0 unattended-select-outpu-input"
                  overflow
                  return-object
                  box
                  persistent-hint
                  v-validate="'required'"
                  data-vv-name="target_engine"
                  :error-messages="
                    errors.collect('form-tree-connections.target_engine')
                  "
                ></v-overflow-btn>
                <div
                  class="pt-2 red--text text--lighten-2"
                  v-if="errors.has('form-tree-connections.target_engine')"
                >
                  {{ errors.first("form-tree-connections.target_engine") }}
                </div>
              </v-flex>

              <v-flex xs12 sm12>
                <v-radio-group v-model="connectionType" :mandatory="true" box>
                  <!--                    <div slot="label">Connection type:</div>-->
                  <v-radio
                    label="Incoming"
                    value="incoming"
                    color="indigo"
                  ></v-radio>
                  <v-radio
                    label="Bidirectional"
                    value="bidirectional"
                    color="indigo"
                  ></v-radio>
                </v-radio-group>
              </v-flex>
            </v-layout>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="grey" text @click="closeDialog()">Close</v-btn>
          <v-btn color="blue darken-1" text @click="saveConnection()"
            >Save</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import { mapGetters } from "vuex"
import _ from "lodash"
import EnginesMixins from "@/mixins/engines.js"
import EngineService from "@/common/engine.service"

export default {
  name: "treeview-dialog-connections",
  props: {
    dialog: {
      type: Boolean,
      default: false,
    },
    selected: {
      type: Object,
    },
  },
  data() {
    return {
      connectionType: "incoming",
      targetEngine: null,
    }
  },
  mixins: [EnginesMixins],
  methods: {
    closeDialog() {
      this.$emit("update:dialog", false)
    },

    getEnginesForConnection: function () {
      let app = this
      let enginesList = EngineService.treeDeepFindConnectionEnginesList(
        app.engines,
        app.selected.engine._id
      )
      return enginesList
    },

    saveConnection() {
      let app = this
      app.$validator.validateAll("form-tree-connections").then((result) => {
        if (result) {
          if (!_.isEmpty(app.targetEngine)) {
            let thisEnginePk = app.selected.engine._key
            let targetEnginePk = app.targetEngine._key
            if (app.connectionType == "incoming") {
              app.makeIncomingConnection(targetEnginePk, thisEnginePk)
            } else {
              app.makeBidirectionalConnection(thisEnginePk, targetEnginePk)
            }
          } else {
            console.log("targetEngine is empty")
          }
        } else {
          console.log("Error on validate")
        }
      })
    },
  },
  computed: {
    ...mapGetters({
      engines: "getPresetTreeviewEngines",
    }),
  },
}
</script>

<style>
.unattended-treeview-connections .v-card__text {
  padding: 22px;
}
.unattended-select-outpu-input .v-input__append-inner {
  display: none;
}
</style>
