<template>
  <div data-testid="container-unattended-engines-list">
    <template v-if="view_type == 0">
      <treeview-engines-list></treeview-engines-list>
    </template>

    <template v-else>
      <v-expansion-panel
        v-model="panelEnginesPreset"
        expand
        popout
        id="unattended-expansion-engines-presets"
      >
        <!-- eslint-disable -->
        <v-expansion-panel-content
          v-if="existsEnginesInPresets()"
          v-for="val in presetEngines.presets"
          class="blue-grey darken-4"
          :key="'preset-expansion-' + val.preset.pk"
        >
        <!-- eslint-enable -->
          <template v-slot:header>
            <div>{{ val.preset.name }}</div>
          </template>
          <v-card>
            <v-card-text class="blue-grey lighten-2">
              <unattended-engine-item
                v-for="(engine, engineIndex) in val.items"
                :engine="engine"
                :engine-index="parseInt(engineIndex)"
                :key="`un-eng-item-${engineIndex}`"
              >
              </unattended-engine-item>
            </v-card-text>
          </v-card>
        </v-expansion-panel-content>
      </v-expansion-panel>

      <v-expansion-panel
        v-model="panelEnginesNoPreset"
        expand
        id="unattended-expansion-engines-without-presets"
        class="mt-3"
        width="90%"
      >
        <v-expansion-panel-content
          v-if="existsEnginesWithoutPresets()"
          class="block-engines-without-preset"
          :key="'no-preset-expansion'"
        >
          <template v-slot:header>
            <div>Engines without presets</div>
          </template>
          <v-card>
            <v-card-text>
              <div v-if="presetEngines.nopreset.items">
                <unattended-engine-item
                  v-for="(engine, engineIndex) in presetEngines.nopreset.items"
                  :engine="engine"
                  :engine-index="parseInt(engineIndex)"
                  :key="`un-eng-item-no-preset-${engineIndex}`"
                >
                </unattended-engine-item>
              </div>
            </v-card-text>
          </v-card>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </template>

    <!--<unattended-engine-item
          v-for="(engine, engineIndex) in engines"
          :engine="engine"
          :engine-index="parseInt(engineIndex)">
        </unattended-engine-item>-->
  </div>
</template>

<script>
import UnattendedEngineItem from "@/views/engines/unattended/EngineItem.vue"
import TreeviewEnginesList from "@/views/engines/unattended/treeview/TreeviewEnginesList.vue"
import { mapGetters } from "vuex"

let _ = require("lodash")

export default {
  name: "engines-list",
  data() {
    return {
      panelEnginesPreset: null,
      panelEnginesNoPreset: [true],
    }
  },
  props: {
    view_type: {
      type: Number,
    },
  },
  components: {
    UnattendedEngineItem,
    TreeviewEnginesList,
  },
  methods: {
    existsEnginesInPresets() {
      return !_.isEmpty(this.presetEngines.presets)
    },
    existsEnginesWithoutPresets() {
      return (
        !_.isEmpty(this.presetEngines.nopreset) &&
        !_.isEmpty(this.presetEngines.nopreset.items)
      )
    },
  },
  computed: Object.assign(
    mapGetters({
      getCurrentUser: "currentUser",
      selectedEngine: "getCurrentSelectedEngine",
      selectedConnectionEngine: "getSelectedConnectionEngine",
      engines: "getAllEngines",
      unattendedEngines: "getUnattendedEngines",
      supervisedEngines: "getSupervisedEngines",
      enginesInfo: "getUnattendedEnginesInfo",
      presetEngines: "getPresetAllEngines",
    }),
    {
      fullSignalServerUrl() {
        return "https://signal-useast.arhtengine.com:8889"
      },
    }
  ),
}
</script>

<style lang="scss">
#unattended-expansion-engines-presets.v-expansion-panel--popout
  .v-expansion-panel__container {
  max-width: 99%;
  margin-bottom: 5px;
  /*border-color: #607d8b !important;*/
}

#unattended-expansion-engines-presets.v-expansion-panel--popout
  .v-expansion-panel__container:last-child {
  margin-bottom: 0px;
}

#unattended-expansion-engines-presets.v-expansion-panel--popout
  .v-expansion-panel__container--active {
  max-width: 100%;
  margin-bottom: 16px;
}

#unattended-expansion-engines-without-presets
  .block-engines-without-preset.v-expansion-panel__container--disabled
  .v-expansion-panel__header {
  pointer-events: none;
  padding-top: 5px;
  padding-bottom: 0px;
}
</style>
