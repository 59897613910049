<template>
  <div>
    <v-card class="mb-2">
      <v-toolbar :key="activePresetObj._id">
        <v-toolbar-title>{{ activePresetObj.name }}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn small color="success" dark text @click.stop="save()">Save</v-btn>
      </v-toolbar>
    </v-card>

    <v-card class="mb-2">
      <v-toolbar>
        <div class="subheading font-weight-medium">Main settings</div>
      </v-toolbar>
      <v-card-text>
        <v-radio-group v-model="activePresetObj.type" :mandatory="true" box>
          <div slot="label">Type:</div>
          <v-radio label="Default" value="default" color="primary"></v-radio>
          <v-radio
            label="Studio & Displays"
            value="studio_displays"
            color="primary"
          ></v-radio>
        </v-radio-group>
      </v-card-text>
    </v-card>

    <v-card class="mb-2">
      <v-toolbar>
        <div class="subheading font-weight-medium">Engines</div>
      </v-toolbar>
      <v-card-text class="pa-0">
        <v-form data-vv-scope="form-presets">
          <v-alert
            :value="true"
            type="error"
            v-if="errors.has('form-presets.engine_keys')"
          >
            {{ errors.first("form-presets.engine_keys") }}
          </v-alert>
          <v-alert
            :value="true"
            type="error"
            v-if="errors.has('form-presets.engine_studio_key')"
          >
            {{ errors.first("form-presets.engine_studio_key") }}
          </v-alert>
          <!-- {{engines}} -->
          <!-- <v-divider/> -->
          <!-- {{selectedEngines}} -->
          <v-data-table
            v-model="selectedEngines"
            :headers="headers"
            :items="engines"
            :single-select="false"
            item-key="_key"
            show-select
            :search="search"
            class="elevation-0 border"
            :loading="loading"
          >
            <template v-slot:top>
              <v-row class="px-3">
                <v-col cols="12" md="6">
                  <v-text-field
                    outlined
                    v-model="search"
                    append-icon="mdi-magnify"
                    label="Search"
                    single-line
                    hide-details
                    :style="
                      $vuetify.breakpoint.smAndUp ? 'max-width: 300px' : ''
                    "
                  ></v-text-field>
                </v-col>
              </v-row>
            </template>
            <template v-slot:body="{ items }">
              <tbody>
                <tr v-for="item in items" :key="item._key">
                  <td>
                    <v-checkbox
                      v-model="item.selected"
                      primary
                      hide-details
                      class="ma-0"
                    ></v-checkbox>
                  </td>
                  <td>{{ item.name }}</td>
                  <td>{{ item.serial_number }}</td>
                  <td></td>
                  <td>
                    <template v-if="checkStudio()">
                      <v-radio-group
                        v-model="selectedStudio"
                        v-if="selected"
                        class="radio-preset-studio"
                      >
                        <v-radio
                          class="dt-radiengine_studioso"
                          :value="item._key"
                          :key="item._key"
                        />
                      </v-radio-group>
                    </template>
                  </td>
                  <td class="text-xs-left">{{ item.description }}</td>
                </tr>
              </tbody>
            </template>
          </v-data-table>
        </v-form>
      </v-card-text>
    </v-card>
    <confirm ref="confirmRemovePresetsIntersections"></confirm>
  </div>
</template>

<script>
import _ from "lodash"
import EnginesMixins from "@/mixins/engines.js"
import PresetsMixins from "@/mixins/presets.js"

export default {
  name: "treeview-preset-preview",
  data() {
    return {
      types: {
        default: "Default",
        studio_displays: "Studio & Displays",
      },
      clear_intersection_presets: false,
      search: "",
      loading: false,
      headers: [
        {
          text: "Name",
          value: "name",
        },
        {
          text: "Serial number",
          value: "serial_number",
        },
        {
          text: "Presets",
          value: "presets",
        },
        {
          text: "Studio",
          value: "studio",
        },
        {
          text: "Description",
          value: "description",
        },
      ],
      selectedEngines: [],
      selectedStudio: null,
      editedIndex: -1,
      engines: [],
    }
  },
  props: {
    activePreset: {
      type: Object,
    },
  },
  components: {},
  mixins: [EnginesMixins, PresetsMixins],
  watch: {
    activePreset: {
      // the callback will be called immediately after the start of the observation
      immediate: true,
      handler(newVal, oldVal) {
        let app = this
        app.getEnginesList()
        app.editedIndex = app.activePreset._key
        app.activePresetObj = app.activePreset
      },
    },
    selectedEngines: {
      // the callback will be called immediately after the start of the observation
      immediate: true,
      handler(newVal, oldVal) {
        let selectedEnginesKeys = _.map(newVal, "_key")
        if (!_.includes(selectedEnginesKeys, this.selectedStudio)) {
          this.selectedStudio = null
        }
      },
    },
  },
  methods: {
    getEnginesList: function () {
      let app = this
      let getParams = { params: { preset_key: app.activePreset._key } }
      app.$store
        .dispatch("enginesCompanyGetList", getParams)
        .then(function (response) {
          app.engines = response.data.data.engines
          let engineKeysArr = app.activePreset.engine_keys || []
          app.selectedEngines = _.filter(app.engines, (v) =>
            _.includes(engineKeysArr, v._key)
          )
          app.selectedStudio = app.activePreset.engine_studio_key
        })
    },
  },
}
</script>
