<template>
  <!-- eslint-disable -->
  <v-layout row wrap>
    <v-flex xs12 class="mb-4">
      <v-card color="blue-grey" class="white--text unattended-engine-card">
        <!--<v-layout row>-->
        <!--<v-flex xs12>-->

        <!--<div>-->
        <!--<v-toolbar-->
        <!--color="cyan"-->
        <!--dark-->
        <!--tabs-->
        <!--&gt;-->
        <!--<v-toolbar-side-icon></v-toolbar-side-icon>-->

        <!--<v-toolbar-title>Page title</v-toolbar-title>-->

        <!--<v-spacer></v-spacer>-->

        <!--<v-btn icon>-->
        <!--<v-icon>search</v-icon>-->
        <!--</v-btn>-->

        <!--<v-btn icon>-->
        <!--<v-icon>more_vert</v-icon>-->
        <!--</v-btn>-->

        <!--<template v-slot:extension>-->
        <!--<v-tabs-->
        <!--v-model="tab"-->
        <!--color="cyan"-->
        <!--grow-->
        <!--&gt;-->
        <!--<v-tabs-slider color="yellow"></v-tabs-slider>-->

        <!--<v-tab-->
        <!--v-for="item in items"-->
        <!--:key="item"-->
        <!--&gt;-->
        <!--{{ item }}-->
        <!--</v-tab>-->
        <!--</v-tabs>-->
        <!--</template>-->
        <!--</v-toolbar>-->

        <!--<v-tabs-items v-model="tab">-->
        <!--<v-tab-item-->
        <!--v-for="item in items"-->
        <!--:key="item"-->
        <!--&gt;-->
        <!--<v-card flat>-->
        <!--<v-card-text>{{ text }}</v-card-text>-->
        <!--</v-card>-->
        <!--</v-tab-item>-->
        <!--</v-tabs-items>-->
        <!--</div>-->

        <!--</v-flex>-->
        <!--</v-layout>-->

        <v-layout row>
          <v-flex xs12>
            <div>
              <v-toolbar color="blue-grey" class="white--text" dark tabs>
                <!--<v-toolbar-side-icon></v-toolbar-side-icon>-->

                <v-toolbar-title>{{ engine.Info ? engine.Info.name : "" }}</v-toolbar-title>

                <v-spacer></v-spacer>

                <v-chip
                  v-if="engine.isUnattendedOnline"
                  color="blue-grey darken-2"
                  text-color="white"
                  disabled
                >
                  RM
                </v-chip>

                <v-chip
                  color="blue-grey darken-2 chip"
                  text-color="white"
                  disabled
                >
                  <template v-if="engine.Info.type_unattended == 'asp'">
                    <v-avatar>
                      <v-icon>mdi-monitor</v-icon>
                    </v-avatar>
                    Engine
                  </template>
                  <template v-else>
                    <v-avatar>
                      <v-icon>mdi-web</v-icon>
                    </v-avatar>
                    Web
                  </template>
                </v-chip>

                <v-chip
                  color="blue-grey darken-2 chip"
                  text-color="white"
                  disabled
                  v-if="checkPreset()"
                >
                  <v-avatar>
                    <v-icon>mdi-alpha-p-circle-outline</v-icon>
                  </v-avatar>
                  {{ engine.preset.name }}
                </v-chip>

                <template v-slot:extension>
                  <v-tabs v-model="tab" color="blue-grey">
                    <v-tabs-slider color="yellow"></v-tabs-slider>

                    <v-tab v-for="item in items" :key="item">
                      {{ item }}
                    </v-tab>
                  </v-tabs>
                </template>
              </v-toolbar>

              <v-tabs-items v-model="tab" color="blue-grey">
                <v-tab-item
                  key="Connections"
                  class="pa-4"
                  :transition="false"
                  :reverse-transition="false"
                >
                  <template v-if="realConnections.length > 0">
                    <v-tabs
                      vertical
                      class="v-tabs--vertical"
                      v-model="activeConnectionsTab"
                    >
                      <v-tab
                        v-for="(connection, connKey) in realConnections"
                        class="pt-3"
                        :key="`tab-conn-${connKey}`"
                      >
                        <v-icon
                          left
                          v-if="
                            checkIncomingOutgoing(
                              engineIndex,
                              connection.engineInfo
                            ) === 'outgoing'
                          "
                          >mdi-arrow-right-bold-outline</v-icon
                        >
                        <v-icon
                          left
                          v-else-if="
                            checkIncomingOutgoing(
                              engineIndex,
                              connection.engineInfo
                            ) === 'incoming'
                          "
                          >mdi-arrow-left-bold-outline</v-icon
                        >
                        <v-icon left v-else
                          >mdi-arrow-left-right-bold-outline</v-icon
                        >
                        {{ connection.engineInfo.name }}
                      </v-tab>

                      <v-tab-item
                        v-for="(connection, connKey) in realConnections"
                        :transition="false"
                        :reverse-transition="false"
                        :key="`tab-item-conn-${connKey}`"
                      >
                        <v-card
                          flat
                          v-for="(oneC, kkey) in connection.data"
                          :key="`card-conn-${kkey}`"
                        >
                          <v-card-text>
                            <v-toolbar dense v-if="oneC.Mode == 'Incoming'">
                              <v-overflow-btn
                                v-model="oneC.Out.activeOut"
                                :items="realOutputs"
                                item-text="name"
                                item-value="id"
                                editable
                                label="Select output"
                                hide-details
                                class="pa-0 unattended-select-outpu-input"
                                overflow
                                return-object
                                @click="prevOutput = oneC.Out.activeOut"
                                @change="changeOutputConnection(oneC, $event)"
                              ></v-overflow-btn>

                              <v-divider vertical></v-divider>

                              <v-icon class="pl-4 pr-4"
                                >mdi-arrow-left-bold</v-icon
                              >

                              <v-divider class="mr-2" vertical></v-divider>

                              <v-overflow-btn
                                v-model="oneC.In.activeIn"
                                :items="getConnectionInputs(oneC.In.activeIn)"
                                item-text="name"
                                item-value="id"
                                editable
                                label="Select input"
                                hide-details
                                class="pa-0 unattended-select-outpu-input"
                                overflow
                                return-object
                                @click="prevInput = oneC.In.activeIn"
                                @change="changeInputConnection(oneC, $event)"
                              ></v-overflow-btn>

                              <v-divider class="mr-2" vertical></v-divider>

                              <v-btn
                                flat
                                icon
                                color="deep-orange"
                                @click="deleteConnection(oneC)"
                              >
                                <v-icon>mdi-delete</v-icon>
                              </v-btn>
                            </v-toolbar>

                            <v-toolbar dense v-if="oneC.Mode == 'Outgoing'">
                              <v-overflow-btn
                                v-model="oneC.In.activeIn"
                                :items="realInputs"
                                item-text="name"
                                item-value="id"
                                editable
                                label="Select input"
                                hide-details
                                class="pa-0 unattended-select-outpu-input"
                                overflow
                                return-object
                                @click="prevInput = oneC.In.activeIn"
                                @change="changeInputConnection(oneC, $event)"
                              ></v-overflow-btn>

                              <v-divider vertical></v-divider>

                              <v-icon class="pl-4 pr-4"
                                >mdi-arrow-right-bold</v-icon
                              >

                              <v-divider class="mr-2" vertical></v-divider>

                              <v-overflow-btn
                                v-model="oneC.Out.activeOut"
                                :items="getOtherEngineOutputsList(oneC)"
                                item-text="name"
                                item-value="id"
                                editable
                                label="Select output"
                                hide-details
                                class="pa-0 unattended-select-outpu-input"
                                overflow
                                return-object
                                @click="prevOutput = oneC.Out.activeOut"
                                @change="changeOutputConnection(oneC, $event)"
                              ></v-overflow-btn>

                              <v-divider class="mr-2" vertical></v-divider>

                              <v-btn
                                flat
                                icon
                                color="deep-orange"
                                @click="deleteConnection(oneC)"
                              >
                                <v-icon>mdi-delete</v-icon>
                              </v-btn>
                            </v-toolbar>
                          </v-card-text>
                        </v-card>
                      </v-tab-item>
                    </v-tabs>
                  </template>
                  <template v-else>
                    <v-alert
                      type="info"
                      :value="true"
                      color="blue-grey darken-2"
                    >
                      No connections available.
                    </v-alert>
                  </template>
                </v-tab-item>

                <v-tab-item
                  key="Inputs"
                  class="pa-4"
                  :transition="false"
                  :reverse-transition="false"
                >
                  <template v-if="realInputs.length > 0">
                    <v-layout row wrap>
                      <v-flex xs12 lg12 mb-3>
                        <v-expansion-panel popout>
                          <draggable
                            :list="realInputs"
                            @change="changeInputsOrder(engine, realInputs)"
                            class="draggable"
                            handle=".handle"
                          >
                            <!--:move="checkMove"-->
                            <!--:disabled="!enabled"-->
                            <!---->
                            <!--@start="dragging = true"-->
                            <!--@end="dragging = false"-->
                            <!--class="list-group"-->
                            <!--ghost-class="ghost"-->

                            <template
                              v-for="element in realInputs"
                              v-if="element"
                            >
                              <v-expansion-panel-content :key="element.id">
                                <template v-slot:header>
                                  <div>
                                    <v-icon class="pr-2 handle"
                                      >mdi-drag-horizontal</v-icon
                                    >
                                    {{ element.name }}
                                  </div>
                                </template>
                                <v-card>
                                  <v-card-text class="blue-grey darken-2">
                                    Type: {{ element.type }}
                                  </v-card-text>
                                </v-card>
                              </v-expansion-panel-content>
                            </template>
                          </draggable>
                        </v-expansion-panel>
                      </v-flex>
                    </v-layout>
                  </template>

                  <template v-else>
                    <v-alert
                      type="info"
                      :value="true"
                      color="blue-grey darken-2"
                    >
                      No inputs available.
                    </v-alert>
                  </template>
                </v-tab-item>

                <v-tab-item
                  key="Outputs"
                  class="pa-4"
                  :transition="false"
                  :reverse-transition="false"
                >
                  <template v-if="realOutputs.length > 0">
                    <!--<v-list> -->

                    <v-layout row wrap>
                      <v-flex xs12 lg12 mb-3>
                        <v-expansion-panel popout>
                          <draggable
                            :list="realOutputs"
                            @change="changeOutputsOrder(engine, realOutputs)"
                            class="draggable"
                            handle=".handle"
                          >
                            <div
                              v-for="(element, index) in realOutputs"
                              :key="'outputs' + index"
                            >
                              <template v-if="element">
                                <!--<v-expansion-panel-header>-->
                                <!---->
                                <!--<template v-slot:actions>-->
                                <!--<v-icon color="primary">$vuetify.icons.expand</v-icon>-->
                                <!--</template>-->
                                <!--</v-expansion-panel-header>-->
                                <v-expansion-panel-content :key="element.id">
                                  <template v-slot:header>
                                    <div>
                                      <v-icon class="pr-2 handle"
                                        >mdi-drag-horizontal</v-icon
                                      >
                                      {{ element.name }}
                                    </div>
                                  </template>
                                  <v-card>
                                    <v-card-text class="blue-grey darken-2">
                                      Type: {{ element.type }}
                                    </v-card-text>
                                  </v-card>
                                </v-expansion-panel-content>
                              </template>
                            </div>
                          </draggable>
                        </v-expansion-panel>
                      </v-flex>
                    </v-layout>

                    <!--</v-list>-->
                  </template>
                  <template v-else>
                    <v-alert
                      type="info"
                      :value="true"
                      color="blue-grey darken-2"
                    >
                      No outputs available.
                    </v-alert>
                  </template>
                </v-tab-item>
              </v-tabs-items>
            </div>
          </v-flex>
        </v-layout>

        <v-divider light></v-divider>

        <v-card-actions class="pr-3 pl-3">
          <div class="text-xs-center">
            <v-menu
              dark
              min-width="fit-content"
              max-height="300px"
              offset-y
              v-if="getEngineIsOnlineUnattended(engine) == true"
            >
              <v-btn
                slot="activator"
                class="btn-dropdown-trigger-incoming-connections ml-2"
                dark
                flat
                icon
                small
                color="cyan"
              >
                <v-icon>reply</v-icon>
              </v-btn>

              <v-list dark expand>
                <v-list-tile
                  v-for="(item, i) in engines"
                  :key="i"
                  @click="makeIncomingConnection(engineIndex, item.Info.pk)"
                  v-if="parseInt(item.Info.pk) !== parseInt(engineIndex)"
                >
                  <v-list-tile-title>{{ item.Info ? item.Info.name : "" }}</v-list-tile-title>
                </v-list-tile>
              </v-list>
            </v-menu>

            <v-menu
              dark
              min-width="fit-content"
              v-if="
                getEngineIsOnlineUnattended(engine) == true &&
                !engineHaveBidirectionalConnections(engine.Info)
              "
              max-height="300px"
              offset-y
            >
              <v-btn
                slot="activator"
                class="success btn-dropdown-trigger-bidirectional-connections ml-2"
                dark
                flat
                icon
                small
                color="cyan"
              >
                <v-icon>phone</v-icon>
              </v-btn>

              <v-list dark expand>
                <v-list-tile
                  v-for="(item, i) in getEnginesBidirectionalConnection()"
                  :key="i"
                  @click="
                    makeBidirectionalConnection(engineIndex, item.Info.pk)
                  "
                  v-if="parseInt(item.Info.pk) !== parseInt(engineIndex)"
                >
                  <v-list-tile-title>
                    {{ item.Info ? item.Info.name : "" }}
                    <span
                      v-if="item.have_bidirectional"
                      class="quadre badge"
                      style="float: none"
                      >busy</span
                    >
                  </v-list-tile-title>
                </v-list-tile>
              </v-list>
            </v-menu>
          </div>

          <v-spacer></v-spacer>

          <div class="text-xs-center">
            <v-chip
              v-if="engine.isUnattendedOnline"
              label
              color="blue-grey darken-2"
              text-color="white"
              class="unattended-chip-status"
              disabled
            >
              CPU: {{ engine.Status.cpu ? engine.Status.cpu + "%" : "-" }}
            </v-chip>

            <v-chip
              v-if="engine.isUnattendedOnline"
              label
              color="blue-grey darken-2"
              text-color="white"
              class="unattended-chip-status"
              disabled
            >
              GPU: {{ engine.Status.gpu ? engine.Status.gpu + "%" : "-" }}
            </v-chip>

            <v-chip
              label
              :color="engine.isLoginedAspx ? 'green' : 'red'"
              text-color="white"
              disabled
            >
              {{ engine.isLoginedAspx ? "ONLINE" : "OFFLINE" }}
            </v-chip>
          </div>
        </v-card-actions>

        <v-snackbar
          v-model="snackbar.show"
          :bottom="snackbar.y === 'bottom'"
          :left="snackbar.x === 'left'"
          :multi-line="snackbar.mode === 'multi-line'"
          :right="snackbar.x === 'right'"
          :timeout="snackbar.timeout"
          :top="snackbar.y === 'top'"
          :vertical="snackbar.mode === 'vertical'"
          :color="snackbar.color"
        >
          {{ snackbar.text }}
          <v-btn color="pink" flat @click="snackbar.show = false">
            Close
          </v-btn>
        </v-snackbar>
      </v-card>
    </v-flex>

    <confirm ref="confirmManageConnection"></confirm>
  </v-layout>
</template>

<script>
/* eslint-disable */
import { mapGetters } from "vuex"
import EnginesMixins from "@/mixins/engines.js"
import VerticalTabs from "@/components/VerticalTabs.vue"
import _ from "lodash"
import Draggable from "vuedraggable"
import Confirm from "@/components/Confirm"

export default {
  name: "unattended-engine-item",
  components: {
    VerticalTabs,
    Draggable,
    Confirm,
  },
  mixins: [EnginesMixins],
  data() {
    return {
      currentUserId: null,
      tab: 0,
      items: ["Connections", "Inputs", "Outputs"],
      snackbar: {
        show: false,
        y: "top",
        x: null,
        mode: "",
        timeout: 3000,
        text: "No text.",
        color: "success",
      },
      activeConnectionsTab: null,
      realInputs: [],
      realOutputs: [],
      realConnections: [],
      prevInput: null,
      prevOutput: null,
    }
  },
  props: {
    engine: {
      type: Object,
    },
    engineIndex: {
      type: Number,
    },
  },
  created: function () {
    let app = this
    app.currentUserId = app.getCurrentUser["pk"]
  },
  watch: {
    engine: function (newVal, oldVal) {
      let app = this
      app.realInputs = app.getRealInputsFiltered(newVal.Inputs)
      app.realOutputs = app.getRealOutputsFiltered(newVal.Outputs)
      app.realConnections = _.filter(
        newVal.Connections,
        (v, k) =>
          v &&
          v.data.length > 0 &&
          typeof app.engines[k] !== "undefined" &&
          app.engines[k].Show
      )
    },
  },
  methods: {
    checkPreset() {
      return _.isEmpty(this.engine.preset, "pk")
    },

    getOtherEngineInputsList(connection) {
      let inputs = this.engines[connection.Out.activeOut.engine.pk].Inputs
      return this.getRealInputsFiltered(inputs)
    },

    getOtherEngineOutputsList(connection) {
      let outputs = this.engines[connection.Out.activeOut.engine.pk].Outputs
      return this.getRealOutputsFiltered(outputs)
    },

    getRealInputsFiltered(items) {
      return _.filter(items, (v) => v.type !== "WebRTC")
    },

    getRealOutputsFiltered(items) {
      return _.filter(items, (v) => v.type !== "WebRTC")
    },

    getConnectionInputs: function (connIn) {
      let inputs = []
      if (Object.keys(connIn).length > 0) {
        let selectedEngine =
          typeof connIn.engine !== "undefined" && connIn.engine
            ? connIn.engine.pk
            : null
        if (selectedEngine) {
          inputs = this.engines[selectedEngine].Inputs
          inputs = inputs.filter(function (inpt) {
            return inpt.type !== "WebRTC"
          })
        }
      }
      return inputs
    },

    handleSpecialErrors(res) {
      _.forEach(res.data, function (value, key) {
        if (key == "unattended_incoming_connection_create") {
          alert(value.join("\r\n"))
        }
      })
    },

    getEngineParamValue: function (engineId, param) {
      if (param == "cpu" || param == "gpu") {
        let value = "0%"
        if (
          engineId.toString() in this.enginesInfo &&
          typeof this.enginesInfo[engineId][param] !== "undefined"
        ) {
          value = this.enginesInfo[engineId][param]
          value = value == -1 ? "-" : value + "%"
        }
        return value
      } else {
        let value = false
        if (
          engineId.toString() in this.enginesInfo &&
          typeof this.enginesInfo[engineId][param] !== "undefined"
        ) {
          value = this.enginesInfo[engineId][param]
        }
        return value
      }
    },

    getEnginesBidirectionalConnection: function () {
      let app = this
      let selectedEngine = app.getSelectedEngine()
      let bothConnEnginesList = []
      _.forEach(app.engines, function (engine, key) {
        if (engine.Info.name !== selectedEngine.name) {
          engine["have_bidirectional"] = app.engineHaveBidirectionalConnections(
            engine.Info
          )
          bothConnEnginesList.push(engine)
        }
      })
      return bothConnEnginesList
    },

    engineHaveBidirectionalConnections: function (currentEngine, targetEngine) {
      let app = this
      let have = false
      let targetEngineCurr = targetEngine || undefined
      if (typeof app.engines[currentEngine.pk] !== "undefined") {
        // &&  typeof(app.engines[selectedEngine.pk].Connections[currentEngine.pk]) !== 'undefined'
        if (targetEngineCurr) {
          // check if engine have connections with concret engine target
          if (
            typeof app.engines[currentEngine.pk].Connections[
              targetEngineCurr.pk
            ] !== "undefined"
          ) {
            let connData =
              app.engines[currentEngine.pk].Connections[targetEngineCurr.pk]
            if (!have && connData.data.length >= 2) {
              have = app.checkSingleConnectionHaveBidirectional(connData)
            }
          }
        } else {
          // check in general that engine have connections
          let connections = app.engines[currentEngine.pk].Connections
          connections.forEach((connData, key) => {
            if (!have && connData.data.length >= 2) {
              have = app.checkSingleConnectionHaveBidirectional(connData)
            }
          })
        }
      }
      return have
    },

    checkSingleConnectionHaveBidirectional: function (connData) {
      let haveOutgoing = false
      let haveIncoming = false
      connData.data.forEach((conn, key2) => {
        if (conn.Mode == "Incoming") {
          haveOutgoing = true
        }
        if (conn.Mode == "Outgoing") {
          haveIncoming = true
        }
      })
      let have = haveOutgoing && haveIncoming
      return have
    },

    engineClearBidirectionalConnections: function (engine) {
      let app = this
      let connections = app.getEngineConnections(engine.pk)
      connections.forEach((conns, targetEnginePk) => {
        if (
          app.engineHaveBidirectionalConnections(
            engine,
            app.getEngineByPk(targetEnginePk)
          )
        ) {
          let connsData = conns.data
          let firstIncomingConn = connsData.filter(
            (connF) => connF.Mode == "Incoming"
          )[0]
          app.deleteConnection(firstIncomingConn)
          let firstOutgoingConn = connsData.filter(
            (connF) => connF.Mode == "Outgoing"
          )[0]
          app.deleteConnection(firstOutgoingConn)
          // $.each(conns.data, function(key, connn){
          //     app.deleteConnection(connn);
          // })
        }
      })
    },

    getEngineByPk: function (pk) {
      return this.engines[pk].Info || null
    },

    getEngineConnections: function (pk) {
      return this.engines[pk].Connections || null
    },

    getEngineIsLoginedAspx: function (engine) {
      let app = this
      let currentUser = app.currentUserId
      let isActive = false
      let curentEngineId = engine.Info.pk
      //let isOnline = app.getEngineParamValue(curentEngineId, 'isLoginedAspx');
      let isOnline = app.engines[curentEngineId].isLoginedAspx
      // console.log('VVV enginesInfo---', app.enginesInfo);
      //let isOnline = if() app[curentEngineId].isLoginedAspx;
      let checkWebEngine =
        engine.Info.type_unattended == "web" &&
        typeof engine.Info.user !== "undefined" &&
        engine.Info.user.pk == currentUser &&
        isOnline
      let checkAspxEngine = engine.Info.type_unattended == "asp" && isOnline
      if (checkAspxEngine || checkWebEngine) {
        isActive = true
      }
      return isActive
    },

    getEngineIsOnlineUnattended: function (engine) {
      let app = this
      let currentUser = app.currentUserId
      let isActive = false
      let curentEngineId = engine.Info.pk
      let isUnattendedOnline = app.engines[curentEngineId].isUnattendedOnline
      // console.log('isLoginedAspx 1111--', isUnattendedOnline);
      //let isOnline = app.getEngineParamValue(curentEngineId, 'is_online_unattended');
      let checkWebEngine =
        engine.Info.type_unattended == "web" &&
        !_.isEmpty(engine.Info.user) &&
        engine.Info.user.pk == currentUser &&
        isUnattendedOnline
      let checkAspxEngine =
        engine.Info.type_unattended == "asp" && isUnattendedOnline
      if (checkAspxEngine || checkWebEngine) {
        isActive = true
      }
      return isActive
    },

    getSelectedEngine: function (mode) {
      let modeS = mode || ""
      // выбрать engine
      if (this.selectedEngine === -1) {
        return "None"
      } else {
        if (modeS !== "") {
          if (typeof this.engines[this.selectedEngine] !== "undefined") {
            return this.engines[this.selectedEngine].Info[modeS]
          } else {
            return null
          }
        } else {
          return this.engines[this.selectedEngine].Info
        }
      }
    },

    deleteConnection: function (connection) {
      let app = this
      console.log("connection on delete--", connection)
      app.$refs.confirmManageConnection
        .open("Delete", "Are you sure you want to delete this connection?", {
          color: "red darken-4",
        })
        .then((confirm) => {
          if (confirm) {
            let switcherIn = connection.In.switcher
            let switcherOut = connection.Out.switcher
            let data = {
              switcher_in_id: switcherIn.id || "",
              switcher_out_id: switcherOut.id || "",
            }
            app.$store
              .dispatch("unattendedEnginesConnectionDeleteAction", data)
              .then(({ result }) => {
                app.$root.$emit("refreshUnattendedData")
              })
          }
        })
    },

    changeInputConnection: function (connection, event) {
      let app = this
      app.$refs.confirmManageConnection
        .open("Input change", "Do you want to change input in connection?", {
          color: "blue-grey darken-1",
        })
        .then((confirm) => {
          if (confirm) {
            let switcherIn = connection.In.switcher
            let switcherOut = connection.Out.switcher
            let newVal = event.id
            let data = {
              switcher_in_id: switcherIn.id || null,
              switcher_out_id: switcherOut.id || null,
              new_val: newVal,
            }
            app.$store
              .dispatch("unattendedInputChangeAction", data)
              .then(({ result }) => {
                app.$root.$emit("refreshUnattendedData")
              })
            app.prevInput = null
          } else {
            // set select to previous value
            connection.In.activeIn = app.prevInput
          }
        })
    },

    changeOutputConnection: function (connection, event) {
      let app = this
      app.$refs.confirmManageConnection
        .open("Output change", "Do you want to change output in connection?", {
          color: "blue-grey darken-1",
        })
        .then((confirm) => {
          if (confirm) {
            let switcherIn = connection.In.switcher
            let switcherOut = connection.Out.switcher
            let newVal = event.id
            let data = {
              switcher_in_id: switcherIn.id || null,
              switcher_out_id: switcherOut.id || null,
              new_val: newVal,
            }
            app.$store
              .dispatch("unattendedOutputChangeAction", data)
              .then(({ result }) => {
                app.$root.$emit("refreshUnattendedData")
              })
            app.prevOutput = null
          } else {
            // set select to previous value
            connection.Out.activeOut = app.prevOutput
          }
        })
    },

    SwitcherChangeOutput: function (
      engine_name,
      switcher,
      new_output_name,
      old_output_name
    ) {
      this.SwitcherDelOutput(engine_name, switcher, old_output_name)
      this.SwitcherAddOutput(engine_name, switcher, new_output_name)
    },

    delOutputConnection: function (engine_name, switcher, old_output_name) {
      self.connections[engine_name].send(
        JSON.stringify({
          type: "SwitcherDelOutput",
          message: {
            command: true,
            SwitcherItName: switcher.name,
            OutputItName: old_output_name,
          },
        })
      )
    },
  },

  computed: {
    ...mapGetters({
      selectedEngine: "getCurrentSelectedEngine",
      selectedConnectionEngine: "getSelectedConnectionEngine",
      getCurrentUser: "currentUser",
      enginesInfo: "getUnattendedEnginesInfo",
      conn_websocket_company: "getWebsocketConnectionCompany",
      engines: "getAllEngines",
      presetEngines: "getPresetAllEngines",
    }),
  },
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/components/vertical-tabs.scss";
.btn-dropdown-trigger-incoming-connections.v-btn--icon {
  background-color: #2196f3 !important;
  border-color: #2196f3 !important;
}

.btn-dropdown-trigger-bidirectional-connections.v-btn .v-btn__content .v-icon,
.btn-dropdown-trigger-incoming-connections.v-btn .v-btn__content .v-icon {
  color: #fff;
}
.unattended-chip-status {
  width: 75px;
}
.draggable {
  /*cursor: all-scroll;*/
  width: 100% !important;
}
.unattended-engine-card
  .v-expansion-panel--popout
  .v-expansion-panel__container,
.v-expansion-panel--inset .v-expansion-panel__container {
  max-width: 100%;
}
.v-expansion-panel__container .handle {
  vertical-align: bottom;
}
.unattended-select-outpu-input {
  width: 40% !important;
}
</style>
