import _ from "lodash"
import GeneralMixin from "@/mixins/general"
import Confirm from "@/components/Confirm"

export default {
  components: {
    Confirm,
  },
  mixins: [GeneralMixin],
  data() {
    return {}
  },
  methods: {
    checkStudio(header = null) {
      if (header) {
        return (
          (header.value == "studio" &&
            this.activePreset.type == "studio_displays") ||
          header.value != "studio"
        )
      }
      return this.activePreset.type == "studio_displays"
    },

    changeSort(header) {
      let column = header.value
      if (header.sortable === undefined || header.sortable === true) {
        if (this.pagination.sortBy === column) {
          this.pagination.descending = !this.pagination.descending
        } else {
          this.pagination.sortBy = column
          this.pagination.descending = false
        }
      }
    },

    toggleAll() {
      if (this.selectedEngines.length) this.selectedEngines = []
      else this.selectedEngines = this.engines.slice()
    },

    createPreset(formData) {
      let self = this
      self.$validator.errors.clear("form-presets")

      self.$store
        .dispatch("presetsEnginesCreate", formData)
        .then(() => {
          self.$emit("preset-data-saved")
          self.closeDialog()
          let params = {
            text:
              "Preset " + self.activePreset.name + " was created successfully.",
            show: true,
          }
          self.$root.$emit("snackbar", params)
        })
        .catch((error) => {
          self.handlePresetsErrors(error)
        })
    },

    editPreset(formData, save) {
      let self = this
      self.$validator.errors.clear("form-presets")
      let saveData = save || false
      if (saveData) {
        self.$store
          .dispatch("presetsEnginesEdit", formData)
          .then(() => {
            self.$emit("preset-data-saved")
            self.closeDialog()
            let params = {
              text:
                "Preset " +
                self.activePreset.name +
                " was edited successfully.",
              show: true,
            }
            self.$root.$emit("snackbar", params)
          })
          .catch((error) => {
            self.handlePresetsErrors(error)
          })
      }
    },

    save() {
      let self = this
      let selectedEnginesIds = _.map(self.selectedEngines, "_key")
      let formData = {
        data: {
          name: self.activePreset.name,
          type: self.activePreset.type,
          status: self.activePreset.status,
          engine_keys: selectedEnginesIds,
          clear_intersection_presets: self.clear_intersection_presets,
        },
      }
      if (self.selectedStudio) {
        formData.data["engine_studio_key"] = self.selectedStudio
      }

      if (self.editedIndex > -1) {
        formData["slug"] = self.editedIndex
        self.editPreset(formData, true)
      } else {
        self.createPreset(formData)
      }
    },

    handlePresetsErrors(error) {
      let self = this
      let errorDescription = _.get(error.response, [
        "data",
        "error",
        "fields",
        "engine_keys",
        "description",
      ])
      if (
        errorDescription &&
        errorDescription.includes(
          "There are engines used in another active preset"
        )
      ) {
        self.handleErrorIntersections(errorDescription)
      } else {
        self.handleValidationErrors(error.response, "form-presets")
      }
    },

    handleErrorIntersections(response) {
      let self = this
      let stringErr =
        response + "." + "<br> Do you want to set status for them inactive?"
      self.$refs.confirmRemovePresetsIntersections
        .open("Reset presets", stringErr, { color: "red darken-4" })
        .then((confirm) => {
          if (confirm) {
            self.clear_intersection_presets = true
            self.save()
          }
        })
    },

    closeDialog() {
      let self = this
      self.$emit("update:dialog", false)
      setTimeout(function () {
        self.activeActionTab = null
        self.clear_intersection_presets = false
        self.$validator.errors.clear("form-presets")
        self.search = ""
      }, 20)
    },

    getSinglePresetInfo(id) {
      let self = this
      let getParams = {
        slug: id,
      }
      self.$store
        .dispatch("presetsEnginesGetList", getParams)
        .then(function (response) {
          self.activePreset = response.data.data.preset
        })
        .catch(function () {
          // console.log('error on PRESETS_ENGINES_GET_LIST', err);
        })
    },
  },
}
