<template>
  <div class="unattended-treeview-connections">
    <template v-if="Object.keys(selected.connections).length > 0">
      <template v-for="(group, gKey) in selected.connections">
        <table class="pa-3 t-unattended-conns" :key="gKey">
          <thead>
            <tr>
              <th
                class="text-xs-left py-2 font-weight-bold grey--text text--lighten-1"
              >
                {{ getConnectionData(group[0])["h_source"] }}
              </th>
              <th class="text-xs-left py-2 font-weight-bold"></th>
              <th
                class="text-xs-left py-2 font-weight-bold grey--text text--lighten-1"
              >
                {{ getConnectionData(group[0])["h_destination"] }}
              </th>
              <th class="text-xs-left py-2 font-weight-bold"></th>
            </tr>
          </thead>
          <tbody>
            <template v-for="(oneC, kkey) in group">
              <tr
                v-if="getConnectionData(oneC)['type'] == 'incoming'"
                class="grey darken-4"
                :key="kkey"
              >
                <td class="t-unattended-dropdwn">
                  <v-overflow-btn
                    v-model="oneC.connection.destination.output_key"
                    :items="selected.outputs"
                    item-text="name"
                    item-value="_key"
                    editable
                    label="Select output"
                    class="pa-0 unattended-select-outpu-input"
                    overflow
                    return-object
                    @click="prevOutput = oneC.connection.destination.output_key"
                    @change="changeOutputConnection(oneC, $event, 'incoming')"
                    hide-details
                  ></v-overflow-btn>
                </td>
                <td>
                  <v-icon class="pl-4 pr-4">mdi-arrow-left-bold</v-icon>
                </td>
                <td class="t-unattended-dropdwn">
                  <v-overflow-btn
                    v-model="oneC.connection.source.input_key"
                    :items="oneC.connection.source.inputs"
                    item-text="name"
                    item-value="_key"
                    editable
                    label="Select input"
                    class="pa-0 unattended-select-outpu-input"
                    overflow
                    return-object
                    @click="prevInput = oneC.connection.source.input_key"
                    @change="changeInputConnection(oneC, $event, 'incoming')"
                    hide-details
                  ></v-overflow-btn>
                </td>
                <td>
                  <v-btn
                    text
                    icon
                    color="deep-orange"
                    @click="deleteConnection(oneC)"
                  >
                    <v-icon>mdi-delete</v-icon>
                  </v-btn>
                </td>
              </tr>
              <tr
                v-if="getConnectionData(oneC)['type'] == 'outgoing'"
                class="grey darken-4"
                :key="kkey"
              >
                <td class="t-unattended-dropdwn">
                  <v-overflow-btn
                    v-model="oneC.connection.source.input_key"
                    :items="selected.inputs"
                    item-text="name"
                    item-value="_key"
                    editable
                    label="Select input"
                    class="pa-0 unattended-select-outpu-input"
                    overflow
                    return-object
                    @click="prevInput = oneC.connection.source.input_key"
                    @change="changeInputConnection(oneC, $event, 'outgoing')"
                    hide-details
                  ></v-overflow-btn>
                </td>
                <td>
                  <v-icon class="pl-4 pr-4">mdi-arrow-right-bold</v-icon>
                </td>
                <td class="t-unattended-dropdwn">
                  <v-overflow-btn
                    v-model="oneC.connection.destination.output_key"
                    :items="oneC.connection.destination.outputs"
                    item-text="name"
                    item-value="_key"
                    editable
                    label="Select output"
                    class="pa-0 unattended-select-outpu-input"
                    overflow
                    return-object
                    @click="prevOutput = oneC.connection.destination.output_key"
                    @change="changeOutputConnection(oneC, $event, 'outgoing')"
                    hide-details
                  ></v-overflow-btn>
                </td>
                <td>
                  <v-btn
                    text
                    icon
                    color="deep-orange"
                    @click="deleteConnection(oneC)"
                  >
                    <v-icon>mdi-delete</v-icon>
                  </v-btn>
                </td>
              </tr>
            </template>
          </tbody>
        </table>
      </template>
    </template>

    <template v-else>
      <v-alert type="info" :value="true" color="primary">
        No connections available.
      </v-alert>
    </template>
    <confirm ref="confirmManageConnection"></confirm>
  </div>
</template>

<script>
import { mapGetters } from "vuex"
import EnginesMixins from "@/mixins/engines.js"
import _ from "lodash"

export default {
  name: "treeview-connections-list",
  data() {
    return {}
  },
  props: {
    selected: {
      type: Object,
    },
  },
  mixins: [EnginesMixins],
  methods: {
    getConnectionData(oneC) {
      let type =
        !_.isEmpty(oneC?.connection?.destination?.engine) &&
        oneC?.connection?.destination?.engine?._key ==
          this.selected?.engine._key
          ? "incoming"
          : "outgoing"
      let destName = !_.isEmpty(oneC?.connection?.destination?.engine)
        ? oneC?.connection?.destination?.engine?.name || "No receiver"
        : "No receiver"
      return {
        type: type,
        h_source: type == "incoming" ? destName : oneC?.engine?.name,
        h_destination: type == "incoming" ? oneC?.engine?.name : destName,
      }
    },
  },
  computed: {
    ...mapGetters({
      presetTreeviewEngines: "getPresetTreeviewEngines",
      engines: "getAllEngines",
    }),
  },
}
</script>

<style>
.unattended-treeview-connections .v-card__text {
  padding: 22px;
}
.unattended-treeview-connections .v-tabs__wrapper--show-arrows {
  margin-left: 0px;
  margin-right: 0px;
}
/*.unattended-treeview-connection-engine-hint {*/
/*    position: absolute;*/
/*    top: -25px;*/
/*}*/
/*.unattended-treeview-connections .v-text-field__details {*/
/*    position: absolute;*/
/*    top: -25px;*/
/*    left: 15px;*/
/*    display: block;*/
/*    width: 100%;*/
/*}*/
/*.unattended-treeview-connections .v-text-field__details .v-messages__message {*/
/*    font-size: 18px;*/
/*    font-weight: bold;*/
/*}*/
/*.unattended-select-outpu-input .v-input__slot {*/
/*    margin-bottom: 0px;*/
/*}*/

.t-unattended-conns {
  border-collapse: separate;
  border-spacing: 0 10px;
  width: 100%;
}

.t-unattended-conns td {
  vertical-align: middle;
}

.t-unattended-conns tr th {
  background: #303030;
  padding-left: 16px;
  font-size: 18px;
}

.t-unattended-conns .t-unattended-dropdwn {
  width: 50%;
}

.t-unattended-conns .v-overflow-btn {
  margin-top: 0 !important;
}

.theme--dark.v-text-field > .v-input__control > .v-input__slot:before {
  border-color: #616161 !important;
}
</style>
