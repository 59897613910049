<template>
  <div data-testid="container-unattended">
    <v-card>
      <v-toolbar>
        <v-card-title data-testid="title-unattended">Engines</v-card-title>
      </v-toolbar>
      <v-card-text>
        <unattended-engines-list
          :view_type="toggle_view_type"
        ></unattended-engines-list>
      </v-card-text>
    </v-card>
    <!--    <web-engine-dialog-->
    <!--        :dialog.sync="dialogWebengine"-->
    <!--        :editedIndex="editedIndex"-->
    <!--    ></web-engine-dialog>-->
  </div>
</template>

<script>
import { mapGetters } from "vuex"
import UnattendedEnginesList from "@/views/engines/unattended/EnginesList.vue"
import _ from "lodash"
import EnginesMixins from "@/mixins/engines.js"
import WsConnMixin from "@/mixins/websocket-connection.js"

export default {
  data() {
    return {
      toggle_view_type: 0,
      // dialogWebengine: true,
      // editedIndexWebengine: -1,
    }
  },

  components: {
    UnattendedEnginesList,
  },

  mixins: [EnginesMixins, WsConnMixin],

  created: function () {
    let self = this
    self.initWebsocketConnection()
    self.getUnattendedData()
    self.$root.$on("refreshUnattendedData", () => {
      self.getUnattendedData()
    })
  },

  methods: {
    // createWebengine(){
    //     this.dialogWebengine = true
    // },
    initWebsocketConnection() {
      let self = this
      self.websocket_common_conn.onmessage = function (value) {
        if (Object.keys(value).length > 0) {
          let data = JSON.parse(value.data)
          if (_.get(data, "path", "") == "1.0.ev.refresh.page") {
            self.getUnattendedData()
          } else if (data.type == "CompInfo") {
            self.saveUnattendedStatus(data.enginePk, data.message, true)
          } else if (data.type == "UnattendedOnlineStatus") {
            self.saveUnattendedOnlineStatus(
              data.enginePk,
              data.is_online_unattended
            )
          } else if (data.type == "UnattendClosed") {
            self.saveUnattendedStatus(
              data.enginePk,
              { cpu: null, gpu: null },
              false
            )
          } else if (data.type !== "UnCmd") {
            self.$store.dispatch("fillDataUnattendedEngines", data)
          }
        }
      }

      self.websocket_common_conn.onopen = function (e) {
        // console.log('onopen', e)
      }

      self.websocket_common_conn.onclose = function (e) {
        // console.error('General socket connection closed unexpectedly')
        self.$store.dispatch("unattendedResetEnginesStatus").then(function () {
          self.getUnattendedData()
        })
      }
    },
    saveUnattendedOnlineStatus(enginePk, isUnattendedOnline) {
      if (enginePk) {
        let dataEnginesInfo = {
          enginePk: enginePk,
          isUnattendedOnline: isUnattendedOnline,
        }
        this.$store.dispatch("unattendedSaveOnlineStatus", dataEnginesInfo)
      }
    },
    saveUnattendedStatus(enginePk, status, isUnattendedOnline) {
      if (enginePk) {
        let dataEnginesInfo = {
          enginePk: enginePk,
          Status: status,
          isUnattendedOnline: isUnattendedOnline,
        }
        this.$store.dispatch("unattendedSaveEnginesStatus", dataEnginesInfo)
      }
    },
    getUnattendedData() {
      this.$store.dispatch("unattendedEnginesGetList", {
        params: { params: { p: 1, pp: 500 } },
      })
    },
  },

  computed: {
    ...mapGetters({
      websocket_common_conn: "getWebsocketCommonConnection",
      getCurrentUser: "currentUser",
      selectedEngine: "getCurrentSelectedEngine",
      selectedConnectionEngine: "getSelectedConnectionEngine",
      engines: "getAllEngines",
      unattendedEngines: "getUnattendedEngines",
      supervisedEngines: "getSupervisedEngines",
      enginesInfo: "getUnattendedEnginesInfo",
    }),
  },
}
</script>
