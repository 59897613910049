<template>
  <div>
    <div
      v-if="!selected"
      class="title grey--text text--lighten-1 font-weight-light"
      style="align-self: center"
    >
      Select a Engine
    </div>

    <template>
      <!-- {{selected}} -->
      <v-card class="mb-2">
        <v-toolbar :key="selected.engine.id">
          <v-toolbar-title>{{ selected.engine.name }}</v-toolbar-title>
          <span v-if="selected.engine.description" class="pl-3">{{
            selected.engine.description
          }}</span>
          <v-spacer></v-spacer>

          <v-chip label disabled text-color="white">
            <span class="font-weight-bold pr-1">Type:</span>
            {{ selected.engine._role }}
          </v-chip>
        </v-toolbar>
      </v-card>

      <v-card class="mb-2">
        <v-toolbar>
          <div class="subheading font-weight-medium">Connections</div>
          <v-spacer></v-spacer>
          <v-btn @click="connectTo" color="primary" dark>
            <v-icon dark>add</v-icon>
            Add connection
          </v-btn>
        </v-toolbar>

        <v-card-text>
          <treeview-connections-list :selected="selected" />
        </v-card-text>
      </v-card>
      <v-row>
        <v-col cols="12" sm="12" md="6">
          <v-card class="mb-2">
            <v-toolbar>
              <div class="subheading font-weight-medium">Inputs</div>
            </v-toolbar>
            <v-card-text>
              <treeview-inputs-list :selected="selected" />
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12" sm="12" md="6">
          <v-card class="mb-2">
            <v-toolbar>
              <div class="subheading font-weight-medium">Outputs</div>
            </v-toolbar>
            <v-card-text>
              <treeview-outputs-list :selected="selected" />
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </template>

    <treeview-dialog-connections
      :dialog.sync="dialog"
      :selected="selected"
    ></treeview-dialog-connections>
  </div>
</template>

<script>
import { mapGetters } from "vuex"
import _ from "lodash"
import EnginesMixins from "@/mixins/engines.js"
import TreeviewConnectionsList from "./TreeviewConnectionsList"
import TreeviewInputsList from "./TreeviewInputsList"
import TreeviewOutputsList from "./TreeviewOutputsList"
import TreeviewDialogConnections from "./TreeviewDialogConnections"

export default {
  name: "treeview-item-preview",
  data() {
    return {
      dialog: false,
    }
  },
  props: {
    selected: {
      type: Object,
    },
  },
  components: {
    TreeviewConnectionsList,
    TreeviewInputsList,
    TreeviewOutputsList,
    TreeviewDialogConnections,
  },
  mixins: [EnginesMixins],
  methods: {
    getConnectionInputs: function (connIn) {
      let inputs = []
      if (Object.keys(connIn).length > 0) {
        let selectedEngine =
          typeof connIn.engine !== "undefined" && connIn.engine
            ? connIn.engine.pk
            : null
        if (selectedEngine) {
          inputs = this.presetTreeviewEngines[selectedEngine].Inputs
          inputs = inputs.filter(function (inpt) {
            return inpt.type !== "WebRTC"
          })
        }
      }
      return inputs
    },
    connectTo() {
      this.dialog = true
    },
  },
  computed: {
    ...mapGetters({
      presetTreeviewEngines: "getPresetTreeviewEngines",
    }),
  },
  filters: {
    roleze: function (value) {
      if (!value) return ""
      return _.capitalize(_.replace(value, "role_", ""))
    },
  },
}
</script>
