<template>
  <v-row>
    <v-col cols="12" xs="12" sm="4" md="3">
      <v-card>
        <v-text-field
          outlined
          v-model="search"
          label="Search Engine"
          hide-details
          clearable
          clear-icon="mdi-close-circle-outline"
        ></v-text-field>

        <v-card-text
          :class="
            $vuetify.breakpoint.smAndUp ? '' : 'treeview-wrapper-collapse'
          "
        >
          <template v-if="checkTreeExists()">
            <v-treeview
              :items="presetTreeviewEngines"
              :open.sync="open"
              :active.sync="active"
              item-key="id"
              hoverable
              activatable
              :search="search"
              id="tree-engines"
            >
              <template v-slot:prepend="{ item, open }">
                <v-icon v-if="item.treetype == 'preset'">
                  {{ open ? "mdi-folder-open" : "mdi-folder" }}
                </v-icon>

                <template v-if="item.treetype == 'engine'">
                  <v-tooltip top v-if="item.isUnattendedOnline">
                    <!-- eslint-disable eslint-disable-next-line vue/no-unused-vars -->
                    <template v-slot:activator="{ on }">
                      <v-icon
                        :class="[
                          item.isUnattendedOnline
                            ? 'green--text text--lighten-3'
                            : 'red--text text--lighten-3',
                        ]"
                      >
                        mdi-remote
                      </v-icon>
                    </template>
                    <span>Unattended</span>
                  </v-tooltip>

                  <v-icon
                    v-else-if="true === false"
                    :class="[
                      checkIsStudio(item)
                        ? 'green--text text--lighten-3'
                        : 'red--text text--lighten-3',
                    ]"
                  >
                    mdi-library-video
                  </v-icon>

                  <v-icon
                    v-else
                    :class="[
                      item.is_online
                        ? 'green--text text--lighten-3'
                        : 'red--text text--lighten-3',
                    ]"
                    class="pl-1"
                  >
                    mdi-monitor
                  </v-icon>
                </template>
              </template>

              <!-- eslint-disable eslint-disable-next-line vue/no-unused-vars -->
              <template v-slot:label="{ item, open }">
                <template v-if="item.treetype == 'preset'">
                  <div>{{ item.name }}</div>
                </template>
                <template v-else>
                  <v-tooltip top v-if="item.description">
                    <template v-slot:activator="{ on }">
                      <div v-on="on">{{ item.name }}</div>
                    </template>
                    <span>{{
                      item.description ? item.description : "No description"
                    }}</span>
                  </v-tooltip>
                  <div v-else>{{ item.name }}</div>
                </template>
              </template>
            </v-treeview>
          </template>
        </v-card-text>
      </v-card>
    </v-col>

    <v-col cols="12" xs="12" sm="8" md="9">
      <treeview-item-preview v-if="selectedItem" :selected="selectedItem" />
      <treeview-preset-preview
        v-if="activePreset"
        :activePreset="activePreset"
      />
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters } from "vuex"
import _ from "lodash"
import TreeviewItemPreview from "./TreeviewItemPreview"
import TreeviewPresetPreview from "./TreeviewPresetPreview"
import EngineService from "@/common/engine.service"
import PresetsMixins from "@/mixins/presets.js"

export default {
  name: "treeview-engines-list",
  components: {
    TreeviewItemPreview,
    TreeviewPresetPreview,
  },
  mixins: [PresetsMixins],
  data: () => ({
    open: [],
    search: null,
    active: [],
    selectedItem: null,
    activePreset: null,
  }),
  watch: {
    active: function (newVal, oldVal) {
      let app = this
      if (!newVal.length) {
        app.open = []
        return undefined
      }
      const id = newVal[0]
      if (_.includes(id, "engine_presets")) {
        app.open = _.includes(app.open, id) ? [] : [id]
        app.selectedItem = null
        let findedPreset = EngineService.treeDeepFindById(
          app.presetTreeviewEngines,
          id
        )
        app.getSinglePresetInfo(findedPreset.response._key)
      } else {
        app.activePreset = null
        app.getSingleEngineInfo(id)
      }
    },
  },
  mounted: function () {
    let app = this
    app.$root.$on("refreshUnattendedData", () => {
      let id = app.active[0]
      if (!_.isUndefined(id)) {
        app.getSingleEngineInfo(id)
      }
    })
    setTimeout(function () {
      let presetsOpen = app.$route.query.presets
      presetsOpen = _.isArray(presetsOpen) ? presetsOpen : [presetsOpen]
      if (presetsOpen.length > 0) {
        let openedPresets = _.filter(app.presetTreeviewEngines, (v) =>
          _.includes(presetsOpen, v.name)
        )
        app.open = _.map(openedPresets, "id")
        if (openedPresets.length == 1) {
          app.active = [openedPresets[0].id]
        }
      }
    }, 500)
  },
  computed: {
    ...mapGetters({
      engines: "getAllEngines",
      presetTreeviewEngines: "getPresetTreeviewEngines",
    }),
    filter() {
      return undefined
    },
  },
  methods: {
    getSingleEngineInfo(id) {
      let app = this
      let finded = EngineService.treeDeepFindById(app.presetTreeviewEngines, id)
      app.$store
        .dispatch("unattendedEnginesSingleInfoAction", finded)
        .then(function (response) {
          // app.selectedItem = _.get(app.presetTreeviewEngines, finded.pathString);
          app.selectedItem = response
        })
        .catch(function (err) {
          //console.log('error on UNATTENDED_ENGINES_SINGLE_INFO_ACTION', err);
        })
    },

    checkTreeExists() {
      return !_.isUndefined(this.presetTreeviewEngines)
    },
    checkIsStudio(item) {
      try {
        return item.preset.engine_studio.pk == item.id
      } catch (e) {
        return false
      }
    },
  },
}
</script>

<style lang="scss" scoped>
#tree-engines .v-treeview-node__content {
  overflow-wrap: break-word;
  width: 95%;
  align-items: flex-start;
}
#tree-engines .v-treeview-node__label {
  min-width: 0;
  flex-shrink: unset;
}

#tree-engines .v-treeview-node--click > .v-treeview-node__root,
#tree-engines
  .v-treeview-node--click
  > .v-treeview-node__root
  > .v-treeview-node__content
  > * {
  align-items: self-start;
}
.v-treeview-node {
  cursor: pointer;
}
.treeview-wrapper-collapse {
  max-height: 400px;
  overflow-x: auto;
  overflow-y: auto;
}
</style>
