import Confirm from "@/components/Confirm"
import _ from "lodash"

export default {
  components: {
    Confirm,
  },
  data() {
    return {
      prevInput: null,
      prevOutput: null,
    }
  },
  methods: {
    deleteConnection: function (conn) {
      let app = this
      app.$refs.confirmManageConnection
        .open("Delete", "Are you sure you want to delete this connection?", {
          color: "red darken-4",
        })
        .then((confirm) => {
          if (confirm) {
            app.$store
              .dispatch(
                "unattendedEnginesConnectionDeleteAction",
                conn.connection._key
              )
              .then(() => {
                app.$root.$emit("refreshUnattendedData")
              })
          }
        })
    },

    changeInputConnection: function (conn, event, type) {
      let app = this
      app.$refs.confirmManageConnection
        .open("Input change", "Do you want to change input in connection?", {
          color: "blue-grey darken-1",
        })
        .then((confirm) => {
          if (confirm) {
            let newVal = event._key
            let data = { slug: conn.connection._key }
            if (type == "incoming") {
              data.params = {
                source: {
                  input_key: newVal,
                  output_key: conn.connection.source.output_key,
                },
                destination: {
                  input_key: conn.connection.destination.input_key,
                  output_key: conn.connection.destination.output_key,
                },
              }
            } else {
              data.params = {
                source: {
                  input_key: newVal,
                  output_key: conn.connection.source.output_key,
                },
                destination: {
                  input_key: conn.connection.destination.input_key,
                  output_key: conn.connection.destination.output_key,
                },
              }
            }
            app.$store
              .dispatch("unattendedInputChangeAction", data)
              .then(() => {
                app.$root.$emit("refreshUnattendedData")
              })
            app.prevInput = null
          } else {
            // set select to previous value
            conn.connection.source.input_key = app.prevInput
          }
        })
    },

    changeOutputConnection: function (conn, event, type) {
      console.log("changeOutputConnection")
      let app = this
      app.$refs.confirmManageConnection
        .open("Output change", "Do you want to change output in connection?", {
          color: "blue-grey darken-1",
        })
        .then((confirm) => {
          if (confirm) {
            let newVal = event._key
            let data = { slug: conn.connection._key }
            if (type == "incoming") {
              data.params = {
                source: {
                  input_key: conn.connection.source.input_key,
                  output_key: newVal,
                },
                destination: {
                  input_key: conn.connection.destination.input_key,
                  output_key: conn.connection.destination.output_key._key,
                },
              }
              console.log("incoming")
            } else {
              data.params = {
                source: {
                  input_key: conn.connection.source.input_key,
                  output_key: conn.connection.source.output_key,
                },
                destination: {
                  input_key: conn.connection.destination.input_key,
                  output_key: newVal,
                },
              }
            }
            app.$store
              .dispatch("unattendedOutputChangeAction", data)
              .then(() => {
                app.$root.$emit("refreshUnattendedData")
              })
            app.prevOutput = null
          } else {
            // set select to previous value
            conn.connection.destination.output_key = app.prevOutput
          }
        })
    },

    // Change order for inputs
    changeInputsOrder(currentEngine, orderData) {
      let app = this
      let items = _.map(orderData, "_key")
      let data = { ios: items, engine_id: currentEngine.id }
      app.$store
        .dispatch("unattendedInputsChangeOrderAction", data)
        .then(() => {
          let params = {
            text:
              "Inputs order for engine " +
              currentEngine.name +
              " was changed successfully.",
            show: true,
          }
          app.$root.$emit("snackbar", params)
        })
        .catch(() => {
          // console.log('On error UNATTENDED_INPUTS_CHANGE_ORDER_ACTION', response);
        })
    },

    // Change order for outputs
    changeOutputsOrder(currentEngine, orderData) {
      let app = this
      let items = _.map(orderData, "_key")
      let data = { ios: items, engine_id: currentEngine.id }
      app.$store
        .dispatch("unattendedOutputsChangeOrderAction", data)
        .then(() => {
          let params = {
            text:
              "Outputs order for engine " +
              currentEngine.name +
              " was changed successfully.",
            show: true,
          }
          app.$root.$emit("snackbar", params)
        })
        /* eslint-disable-next-line */
        .catch(({ response }) => {
          // console.log('On error UNATTENDED_INPUTS_CHANGE_ORDER_ACTION', response);
        })
    },

    makeIncomingConnection: function (thisEnginePk, targetEnginePk) {
      let app = this
      let data = {
        source_engine_key: thisEnginePk,
        destination_engine_key: targetEnginePk,
        type: "incoming",
      }
      app.$store
        .dispatch("unattendedIncomingConnectionCreateAction", data)
        .then(() => {
          app.$root.$emit("refreshUnattendedData")
          app.$emit("update:dialog", false)
        })
        .catch(({ response }) => {
          app.handleSpecialErrors(response)
          //console.log('On error UNATTENDED_INCOMING_CONNECTION_CREATE_ACTION', response);
        })
    },

    // --Begin-- Functionality connections in both way
    makeBidirectionalConnection: function (this_engine_pk, target_engine_pk) {
      console.log("makeBidirectionalConnection: ")
      let app = this

      let data = {
        source_engine_key: this_engine_pk,
        destination_engine_key: target_engine_pk,
        type: "bidirectional",
      }

      app.$store
        .dispatch("unattendedBidirectionalConnectionCreateAction", data)
        .then(() => {
          app.$root.$emit("refreshUnattendedData")
          app.$emit("update:dialog", false)
        })
        .catch(({ response }) => {
          app.handleSpecialErrors(response)
          // console.log('On error UNATTENDED_BIDIRECTIONAL_CONNECTION_CREATE_ACTION', res);
        })
    },

    handleSpecialErrors(res) {
      _.forEach(res.data, function (value, key) {
        if (key == "unattended_connection_create") {
          alert(value.join("\r\n"))
        }
      })
    },

    checkIncomingOutgoing: function (selectedEngine, selectedConnectionEngine) {
      let idConnection =
        typeof selectedConnectionEngine !== "undefined"
          ? selectedConnectionEngine.pk
          : null
      let result = "incoming"
      if (idConnection) {
        let data =
          this.engines[selectedEngine].Connections[idConnection]["data"]
        let hasIncoming = false
        let hasOutgoing = false
        _.forEach(data, function (val) {
          if (val.Mode == "Incoming") {
            hasIncoming = true
          } else if (val.Mode == "Outgoing") {
            hasOutgoing = true
          }
        })
        if (hasIncoming && hasOutgoing) {
          result = "both"
        } else if (hasOutgoing) {
          result = "outgoing"
        }
      }
      return result
    },
  },
  //  computed: Object.assign(
  //     mapGetters({
  //         rcEngineData: 'getRcEngineData',
  //     }),
  //     {
  //
  //     },
  // ),
}
